import { z } from 'zod';

export enum Environments {
  Development = 'development',
  Production = 'production',
}

export enum PriceFrequency {
  None = 'None',
  Hourly = 'Hourly',
  Monthly = 'Monthly',
  Once = 'Once',
}

export enum FormNames {
  ADMIN_ADD_USER_TO_ORGANIZATION = 'adminAddUserToOrganization',
  ADMIN_REMOVE_USER_FROM_ORGANIZATION = 'adminRemoveUserFromOrganization',
  ADMIN_DELETE_ORGANIZATION = 'adminDeleteOrganization',
  CONFIGURE_DEVICE_FORM = 'configureDevice',
  DEPLOYMENT_REPROVISION_FORM = 'deploymentReprovision',
  DEPLOYMENT_REPROVISION_CONFIRMATION_FORM = 'deploymentReprovisionConfirmation',
  DCIM_PROVISION_FORM = 'dcimProvision',
  HELPDESK_FORM = 'helpDesk',
  HYPERVISOR_UPDATE_FORM = 'hypervisorUpdate',
  SSH_KEY_FORM = 'sshKey',
  VIRTUAL_MACHINE_POWER_MANAGEMENT_FORM = 'virtualMachinePowerManagement',
  VIRTUAL_MACHINE_DECOMMISSION_FORM = 'virtualMachineDecommission',
}

export const OperatingSystemSchema = z
  .enum(['ubuntu2004', 'ubuntu2204', 'ubuntu-20-04', 'ubuntu-22-04'])
  .nullable();

export type OperatingSystemEnum = z.infer<typeof OperatingSystemSchema>;

export const DeviceCategoryEnum = z.enum([
  '4090',
  '3090',
  'a10',
  'a100',
  'a40',
  'a4000',
  'a5000',
  'a6000',
  'gh200',
  'h100',
  'h200',
  'l40s',
  'mi250',
  'mi300x',
  'cpu',
  'virtual-machine',
]);

export type DeviceCategoryEnum = z.infer<typeof DeviceCategoryEnum>;

export enum DeviceStatus {
  Active = 'Active',
  Planned = 'Planned',
  Staged = 'Staged',
  Failed = 'Failed',
  Offline = 'Offline',
  Inventory = 'Inventory',
  Decommissioning = 'Decommissioning',
  Provisioning = 'Provisioning',
  Provisioned = 'Provisioned',
  Rebooting = 'Rebooting',
  // Virtual Machine Power States
  Starting = 'Starting',
  Running = 'Running',
  PoweredOff = 'Powered Off',
  ShuttingDown = 'Shutting Down',
  Failure = 'Failure',
}
